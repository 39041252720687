@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";
@import "~quill-mention/dist/quill.mention.css";
@import "../projects/payroll/src/styles.css";
@import "~primeng/resources/themes/saga-blue/theme.css";
@import "~primeicons/primeicons.css";
@import "~primeng/resources/primeng.min.css";
@import "~leaflet/dist/leaflet.css";

/* You can add global styles to this file, and also import other style files */

@import "~@vime/core/themes/default.css";
@import "~@vime/core/themes/light.css";

@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~@ng-select/ng-select/themes/default.theme.css";
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "~@angular/material/theming";
// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$kunyek-primary: mat-palette($mat-indigo);
$kunyek-accent: mat-palette($mat-pink, A200, A100, A400);
// The warn palette is optional (defaults to red).
$kunyek-warn: mat-palette($mat-red);
// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$kunyek-theme: mat-light-theme(
  (
    color: (
      primary: $kunyek-primary,
      accent: $kunyek-accent,
      warn: $kunyek-warn,
    ),
  )
);
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($kunyek-theme);
// Custom fonts
@font-face {
  font-family: "Ubuntu";
  src: local("Ubuntu"), url(./assets/fonts/Ubuntu-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Pyidaungsu";
  src: local("Pyidaungsu"), url(./assets/fonts/Pyidaungsu.ttf) format("truetype");
}

html {
  // font-size: 15px;
  font-size: 14px;
}

.mb-3 {
  margin-bottom: 0.9rem !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Ubuntu" !important;
}

.bs-datepicker-head, .btn-today-wrapper .btn-success, .btn-clear-wrapper .btn-success{
  background-color: var(--main-color) !important;
  border-color:  var(--main-color) !important;
}

.bs-datepicker-body table td span.selected, .bs-datepicker-body table td.selected span, .bs-datepicker-body table td span[class*="select-"]::after, .bs-datepicker-body table td[class*="select-"] span::after{
  background-color: var(--main-color) !important;
}

.datepicker-readonly[type="text"]:read-only:not(:disabled) {
  background-color:white !important;
}

bs-datepicker-container div.custom-placement{
  left: 87px !important;
}

// body{
//   background-color: whitesmoke !important;
// }
.my-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: none !important;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1) !important;
  border-radius: 0.25rem;
  padding: 10px;
  border-radius: 15px !important;
  .info {
    font-size: 1.1rem !important;
    font-weight: bold;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: var(--main-color) !important;
}

.mat-checkbox-layout {
  width: 100% !important;
}

.mat-checkbox-label-before .mat-checkbox-inner-container {
  order: 1;
  margin-left: auto !important;
  margin-right: 0 !important;
}

.ql-toolbar {
  display: none;
}

.ql-mention-list-container {
  max-height: 260px;
}

.ql-mention-list-item {
  padding: 0px 4px !important;
}

.ql-mention-loading {
  text-align: center;
}

.ql-editor {
  color: black;
  padding: 0 !important;
  font-family: "Ubuntu";
  min-height: 20px;
  max-height: 300px;
  margin-bottom: 5px !important;
}

.ql-editor.ql-blank::before {
  content: "Type a message..." !important;
  font-style: normal !important;
  left: 5px !important;
}

.ql-container {
  border: none !important;
  width: 100%;
  font-family: "Ubuntu";
  word-break: break-all;
}

tbody {
  td {
    font-size: 0.95rem;
    padding: 2px 0.5rem !important;
  }
}

.ql-editor p {
  margin: 0 !important;
}

.bg-white {
  background-color: white;
  width: 100vw;
}

.edit-message {
  .mat-dialog-container {
    overflow: initial !important;
  }
}

.mat-typography {
  font-family: "Ubuntu" !important;
  font-size: 0.95rem !important;
}

.mat-menu-item {
  font-family: "Ubuntu" !important;
  font-size: 0.95rem;
}

.m-main {
  width: 300px;
  border: 1px solid #000;
  margin-top: 30px;
  padding-left: 3px;
}

.m-tit {
  font-size: 15px;
  padding: 5px;
}

.m-profile-image {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  // margin-right: 15px;
  object-fit: cover;
  border: 1px solid lightgray;
}

.m-row {
  padding: 3px;
  display: flex;
  cursor: pointer;
  justify-items: center;
  align-items: center;
}

.m-rightside {
  margin-left: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.m-name {
  color: #000;
  font-size: 15px;
  text-align: left;
  line-height: 20px;
}

.m-id {
  color: grey;
  font-size: 13px;
  line-height: 20px;
}

.content {
  margin: 1rem;
  margin-top: 15px;
  padding-bottom: 30px;
}

.users_row {
  margin-top: 0px !important;
  margin-right: 0px !important;
}

.users_reply_content {
  width: auto;
  height: auto;
  background: white;
  border-left: 4px solid #d1d1d1;
  margin-top: 10px;
  display: block;
  text-align: start;
  margin-right: -40px;
  padding-right: 20px;
  cursor: pointer;
  .username {
    color: var(--main-color);
    padding: 7px 100px 0px 0px;
    margin-left: 10px;
  }
  .username_img {
    color: var(--main-color);
    padding: 3px 0px 0px 10px;
    margin: 0px;
  }
  .msg {
    margin-left: 10px;
    margin-top: -7px !important;
    margin-bottom: 7px;
    span {
      color: black;
    }
  }
  .camera_img {
    height: 17px;
    padding-bottom: 3px;
    padding-left: 10px;
  }
  .reply-img {
    height: 35px;
    object-fit: cover;
    width: 50px;
  }
}

.reply-row {
  flex-wrap: nowrap !important;
  overflow-x: hidden !important;
}

.personal_reply_content {
  width: auto;
  height: auto;
  background: linear-gradient(120deg, #e4e6eb, #e4e6eb);
  border-left: 4px solid #d1d1d1;
  margin-top: 19px;
  margin-bottom: 0px;
  color: black;
  display: block;
  text-align: start;
  margin-right: -40px;
  padding-right: 20px;
  cursor: pointer;
  .username {
    color: var(--main-color);
    padding: 7px 100px 0px 0px;
    margin-left: 10px;
  }
  .username_img {
    color: var(--main-color);
    padding: 3px 0px 0px 10px;
    margin: 0px;
  }
  .row-width {
    width: 150px;
  }
  .msg {
    margin-left: 10px;
    margin-top: -7px !important;
    margin-bottom: 7px;
    span {
      color: black;
    }
  }
  .camera_img {
    height: 17px;
    padding-bottom: 3px;
    padding-left: 10px;
  }
  .reply-img {
    height: 35px;
    object-fit: cover;
    width: 50px;
  }
  .person_msg_col {
    width: auto !important;
  }
}

.message {
  .reply_box {
    background: linear-gradient(120deg, #d4d4d4, #dad8d8);
    width: 100%;
  }
}

.reply_box {
  background: linear-gradient(120deg, #e4e6eb, #e4e6eb);
  border-left: 3px solid var(--border-color);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  min-width: 10rem;
  margin-bottom: 5px;
  .reply_wrapper {
    margin: 0.3rem 5px;
    align-items: center !important;
    font-size: 0.7rem;
    display: flex;
  }
  .msg-username {
    display: flow-root !important;
    text-align: start;
    margin-left: 0.5rem;
  }
  .username {
    color: var(--main-color);
    margin-bottom: 2px;
    margin-top: 6px;
  }
  .username-img {
    color: var(--main-color);
    margin-bottom: 3px !important;
  }
  .camera_img {
    height: 15px;
    margin-top: -20px;
  }
  .reply-img {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    // width: 100%;
  }
  .image {
    height: 50px !important;
    width: 50px !important;
    // margin-top: 0.5rem;
    // margin-bottom: 0.5rem;
    object-fit: cover;
    border-radius: 5px;
    margin-left: 0.5rem;
  }
  .clear-icon {
    color: var(--main-color);
    margin-bottom: 15px;
    font-size: 17px;
    margin-right: 10px;
    cursor: pointer;
  }
  .msg {
    margin-bottom: 7px;
    color: black;
  }
}
.modal-backdrop {
  z-index: -1 !important;
}

@media screen and (max-width: 425px) {
  .reply_box {
    .image {
      // margin-left: -50px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .content {
    margin: 10px !important;
    margin-top: 20px !important;
  }
}

@media only screen and (max-width: 767px) {
  .content {
    margin: 10px !important;
    margin-top: 20px !important;
  }
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input > input {
  background-color: white !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  z-index: 10 !important;
}

// @media only screen and (min-width: 960px) {
//     .navbar .navbar-nav .nav-item .nav-link {
//       padding: 0 0.5em;
//     }
//     .navbar .navbar-nav .nav-item:not(:last-child) .nav-link {
//       border-right: 1px solid var(--border-color);
//     }
//     .nav-item{
//         min-width: 150px;
//         text-align: center;
//     }
// }
.mat-sort-header-indicator,
.mat-sort-header-stem {
  color: white !important;
}

:root {
  --main-color: #2e86c1;
  --button-color: #2e86c1;
  --nav-hover-color: rgb(179 181 234);
  --white-background: #fff;
  --black-background: black;
  --grey-background: #c5cae9;
  --border-color: #bdbdbd;
  --icon-grey-color: rgb(128, 128, 128);
  --hover-color: #033d88;
  --input-color: white;
  --chat-color: white;
  --chat-msg-color: #5192c4;
}

select:focus,
textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  border-color: var(--main-color) !important;
  box-shadow: 0 1px 1px rgba(221, 219, 219, 0.075) inset, 0 0 8px rgba(243, 242, 242, 0.6) !important;
  outline: 0 none !important;
}

select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
  background-color: var(--input-color);
}

input[type="datetime"],
input[type="time"],
input[type="datetime-local"],
input[type="date"] {
  max-height: 35px;
}

.form-select:disabled,
input[type="text"]:disabled,
input[type="password"]:disabled,
input[type="datetime"]:disabled,
input[type="datetime-local"]:disabled,
input[type="date"]:disabled,
input[type="month"]:disabled,
input[type="time"]:disabled,
input[type="week"]:disabled,
input[type="number"]:disabled,
input[type="email"]:disabled,
input[type="url"]:disabled,
input[type="search"]:disabled,
input[type="tel"]:disabled,
input[type="color"]:disabled,
textarea:disabled {
  // background-color: var(--border-color) !important;
  background-color: #e9ecef !important;
  color: #000000ad;
}

.report-input-box {
  width: 200px !important;
}

.form-check-input,
.form-check-label {
  cursor: pointer;
}

.form-check-input[type="checkbox"] {
  border-radius: 0 !important;
}

.form-check-input:checked {
  background-color: var(--main-color) !important;
  border-color: var(--main-color) !important;
}

.red-checkbox {
  color: red;
  .form-check-input {
    border-color: red !important;
  }
  .form-check-input:checked {
    background-color: red !important;
    border-color: red !important;
  }

  .form-check-input:focus {
    border-color: red !important;
    box-shadow: none !important;
    outline: none !important;
  }
}

.form-label,
.col-form-label,
label,
.form-control {
  font-size: 0.95rem !important;
}

// .btn:disabled {
//   display: -webkit-box !important;
// }

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(190, 187, 187) !important;
  opacity: 0.5;
  /* Firefox */
}

.navbar-toggler:focus,
.btn:focus {
  box-shadow: none !important;
}

.btn {
  font-size: 0.95rem !important;
}

.btn-icon {
  color: #a7a9ab;
  border: none;
  background-color: transparent;
  padding: 0.5rem;
  position: relative;
  overflow: hidden;
  transition: color 0.15s ease-in-out, opacity 0.15s ease-in-out, background-color 0.15s ease-in-out;
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 1.5rem;
  line-height: 1;
  border-radius: 50% !important;
  justify-content: center;
}

.btn-custom {
  color: var(--white-background) !important;
  background-color: var(--button-color) !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: var(--hover-color) !important;
    // color: white !important;
  }
}

.btn-outline-custom {
  color: var(--button-color) !important;
  background-color: var(--white-background) !important;
  border: 1px solid var(--button-color) !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: var(--button-color) !important;
    color: var(--white-background) !important;
  }
}

.btn-outline-black {
  color: black !important;
  background-color: var(--white-background) !important;
  border: 1px solid black !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: black !important;
    color: var(--white-background) !important;
  }
}

.btn-link {
  cursor: pointer;
}

.btn-outline-info,
.btn-outline-primary,
.btn-outline-danger {
  // width: 40px;
  width: 2.65rem;
}

.btn-outline-primary {
  color: var(--main-color) !important;
  border-color: var(--main-color) !important;
  &:hover {
    background-color: var(--main-color) !important;
    border-color: var(--main-color) !important;
    color: white !important;
  }
}

.remove-input-btn {
  border: 1px solid #da2e25 !important;
  width: 38px !important;
  color: #da2e25 !important;
}

.remove-input-btn:hover {
  background-color: #da2e25 !important;
  mat-icon {
    color: white !important;
  }
}

.close-save-button {
  // display: -webkit-inline-box !important;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.line {
  border-right: 1px solid var(--border-color);
  margin: 0.5rem 1rem;
  height: 20px;
}

.title {
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 1.5;
  word-wrap: break-word;
}

.button-spinner {
  color: var(--white-background) !important;
}

.spinner-border {
  color: var(--button-color);
}

.table-header {
  background-color: var(--button-color);
  color: var(--white-background);
  font-weight: 400;
  font-size: 0.95rem !important;
}

.td-data,
.th-data {
  min-width: 150px;
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 35px;
}

.td-edit {
  cursor: pointer;
}

.label {
  margin-top: auto;
  margin-bottom: auto;
}

.nodata {
  height: 140px;
  vertical-align: middle;
  text-align: center;
  font-size: 1rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.custom-web-breadcrumb {
  margin-bottom: 1rem;
  // height: 60px;
  display: flex;
  align-items: center;
  // padding: 0 1.2rem;
  // box-shadow: 0 2px 4px rgb(0 0 0 / 8%), 0 4px 12px rgb(0 0 0 / 8%);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue",
    sans-serif !important;
  font-size: 1rem;
  font-weight: 600;
  overflow: auto;
  width: 100%;
  flex-wrap: wrap;
  span {
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    // overflow: hidden;
    // text-overflow: ellipsis;
    white-space: normal;
    max-width: 500px;
    line-height: 1.5;
  }
  .active-breadcrumb {
    color: var(--main-color);
    cursor: pointer;
  }
  .fa {
    color: grey;
    opacity: 0.8;
    margin: 0 0.3rem;
    zoom: 1.5;
  }
}

@media screen and (max-width: 700px) {
  // .custom-web-breadcrumb {
  //     padding: 0 1rem !important;
  // }
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1036;
}

.snackbar {
  min-height: 48px;
  // color: #fff !important;
  background-color: white;
  box-shadow: none;
  border: 1px solid var(--border-color);
  // box-shadow: 5px 10px 18px #dad8d8;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 6px -1px, rgb(0 0 0 / 6%) 0px 2px 4px -1px !important;
  // font-weight: bold;
  letter-spacing: 0.2px;
  z-index: 10;
}

.snackbar-success {
  color: #28a745 !important;
}

.snackbar-vote-success {
  background-color: #32cd32;
  color: white;
}

.snackbar-warn {
  color: #dc3545 !important;
}

.snackbar-info {
  color: black !important;
}

.bshadow {
  filter: drop-shadow(5px 5px 5px #cccccc);
}

.attendance-qr-code {
  img {
    height: 200px;
  }
}

table {
  background-color: white;
}

.table {
  background-color: white;
}

.table-responsive {
  overflow-x: auto !important;
  white-space: nowrap;
  background-color: white;
  tr {
    vertical-align: middle;
  }
}

.map-wrapper {
  cursor: pointer;
  color: var(--main-color);
  margin-top: 0.5rem;
}

@media only screen and (max-width: 700px) {
  .event-modal {
    width: 95vw !important;
    height: 90vh !important;
    max-width: none !important;
    max-height: none !important;
  }
  .report-modal {
    width: 95vw !important;
    height: 65vh !important;
    max-width: none !important;
    max-height: none !important;
  }
  .map-modal {
    height: 400px !important;
    width: 97vw !important;
    max-width: none !important;
  }
  .chat-info {
    width: 95vw !important;
    height: 90vh !important;
    max-width: none !important;
  }
  .user-profile-modal {
    width: 97vw !important;
  }
}

@media only screen and (max-width: 700px) {
  .qr-modal {
    width: 100% !important;
    height: 350px !important;
  }
}

.row-custom {
  --bs-gutter-x: 0 !important;
}

// .sticky {
//     position: fixed;
//     top: 70;
//     z-index: 100;
//     background-color: var(--white-background);
// }
.logintype {
  width: 100%;
  &:hover {
    background-color: var(--main-color) !important;
    color: var(--white-background) !important;
  }
}

.menu {
  border: 1px solid lightgray !important;
  box-shadow: 3px 2px 8px -5px darkslategrey !important;
  border-radius: 10px !important;
  min-height: auto !important;
  .menu-item {
    color: var(--main-color) !important;
  }
}

//Chat Image
.conversation-summary-avatar {
  border-radius: 10em;
  position: absolute;
  top: 0.6em;
  left: 0.6em;
  width: 80px;
  height: 80px;
  margin-left: 5px;
  border: 1px solid #c4c4c4;
  object-fit: cover;
}

.conversation-summary-avatar-group-member-wrapper,
.conversation-summary-avatar-group-member {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  object-fit: cover;
}

.conversation-summary-avatar-group-member-wrapper {
  overflow: hidden;
}

.conversation-summary-avatar-group {
  top: 0;
  left: 0;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.conversation-summary-avatar-group[data-number-of-thumbnails="2"]
  .conversation-summary-avatar-group-member-wrapper:first-child {
  left: -25%;
}

.conversation-summary-avatar-group[data-number-of-thumbnails="2"]
  .conversation-summary-avatar-group-member-wrapper:nth-child(2) {
  left: 50%;
}

.conversation-summary-avatar-group[data-number-of-thumbnails="2"]
  .conversation-summary-avatar-group-member-wrapper:nth-child(2)
  .conversation-summary-avatar-group-member {
  left: -25%;
}

.conversation-summary-avatar-group[data-number-of-thumbnails="3"]
  .conversation-summary-avatar-group-member-wrapper:first-child {
  left: -25%;
}

.conversation-summary-avatar-group[data-number-of-thumbnails="3"]
  .conversation-summary-avatar-group-member-wrapper:nth-child(2),
.conversation-summary-avatar-group[data-number-of-thumbnails="3"]
  .conversation-summary-avatar-group-member-wrapper:nth-child(3) {
  left: 50%;
  width: 50%;
  height: 50%;
}

.conversation-summary-avatar-group[data-number-of-thumbnails="3"]
  .conversation-summary-avatar-group-member-wrapper:nth-child(3) {
  top: 50%;
}

.paginate {
  // float: right;
  text-align: center;
}

.eula-body {
  padding-top: 30px;
  text-align: justify;
  line-height: 1.6;
}

// Chat -contacts && Post Tag People
app-chat-contacts {
  .contacts-wrapper {
    height: calc(100vh - 220px) !important;
  }
}

app-create-post {
  .contacts-wrapper {
    height: calc(100vh - 340px) !important;
  }
}

app-chat-contacts,
app-create-post {
  .chat-image {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    object-fit: cover;
    margin: 5px;
    border: 1px solid var(--border-color);
  }
  .list-group-header {
    padding: 0.5rem 1rem;
    color: var(--main-color);
    font-weight: bold;
  }
  .list-group-item {
    background-color: transparent !important;
    cursor: pointer;
    border: none !important;
    border-bottom: 1px solid var(--border-color) !important;
  }
  .list-group {
    border-radius: 0;
  }
  .profile-item {
    display: flex;
    align-items: center;
    .profile-container {
      display: flex;
      flex-direction: column;
      span {
        color: grey;
        font-size: 0.7rem;
      }
    }
    .checkbox-container {
      flex: 1;
      text-align: end;
      margin-right: 0.3rem;
    }
  }
  .member-setup {
    display: flex;
    transition: height 0.15s;
    .member-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 0.5rem 0 0;
      max-width: 200px;
      animation: scaleIn 0.15s ease forwards;
      .image-container {
        position: relative;
        .member-close {
          position: absolute;
          top: 5px;
          right: 5px;
          background-color: #222;
          border-radius: 50%;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          padding: 2px;
          mat-icon {
            zoom: 0.5;
          }
        }
      }
      .info-container {
        display: block !important;
        display: -webkit-box !important;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
      }
    }
  }
  @keyframes scaleIn {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    /* 60% {
            transform: scale(0.4);  
        }
        80% {
            transform: scale(0.8);
            opacity: 1; 
        }    */
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  @-webkit-keyframes scaleIn {
    0% {
      -webkit-transform: scale(0);
      opacity: 0;
    }
    /* 60% {
            -webkit-transform: scale(0.4);
        }
        80% {
            -webkit-transform: scale(0.8);
            opacity: 1; 
        } */
    100% {
      -webkit-transform: scale(1);
      opacity: 1;
    }
  }
  @keyframes scaleOut {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(0);
      opacity: 0;
    }
  }
  @-webkit-keyframes scaleOut {
    0% {
      -webkit-transform: scale(1);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(0);
      opacity: 0;
    }
  }
  .fab-btn {
    position: fixed;
    bottom: 40px;
    margin-left: 24%;
    width: 45px;
    height: 45px;
    font-size: 20px;
    background-color: var(--main-color);
  }
  @media only screen and (max-width: 767px) {
    .fab-btn {
      margin-left: 0;
      right: 30px;
    }
  }
  .hide {
    display: none !important;
  }
}

.comment-section {
  display: flex;
}

.textarea {
  padding-top: 4px;
  width: 100%;
  border: none !important;
  outline: none !important;
  padding-right: 65px;
  &:focus {
    border: none !important;
    outline: none !important;
  }
}

::-webkit-resizer {
  display: none;
}

.input-box {
  width: 100%;
  border-radius: 20px;
  border: 1px solid lightgray;
  min-height: 35px;
  position: relative;
  padding: 5px;
  align-items: center;
  padding-left: 10px;
  padding-bottom: 0px !important;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  .btns {
    display: flex;
    position: absolute;
    bottom: 3px;
    right: 8px;
    .image,
    .send {
      cursor: pointer;
      &:hover {
        box-shadow: 0px 0px 5px 3px lightgrey;
      }
      .mat-icon {
        line-height: 12px !important;
        font-size: 20px !important;
      }
    }
    .mat-icon-button {
      width: 30px;
      height: 30px !important;
    }
    .spinner-border {
      height: 20px !important;
      width: 20px !important;
      margin-bottom: 7px !important;
    }
  }
}

.reply-input-box {
  width: 100%;
  border-radius: 20px;
  border: 1px solid lightgray;
  min-height: 35px;
  position: relative;
  padding: 5px;
  align-items: center;
  padding-left: 10px;
  padding-bottom: 0px !important;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  .textarea {
    padding-top: 0 !important;
  }
  .btns {
    display: flex;
    position: absolute;
    bottom: 3px;
    right: 8px;
    .image,
    .send {
      cursor: pointer;
      &:hover {
        box-shadow: 0px 0px 5px 3px lightgrey;
      }
      .mat-icon {
        line-height: 12px !important;
        font-size: 20px !important;
      }
    }
    .mat-icon-button {
      width: 30px;
      height: 30px !important;
    }
    .spinner-border {
      height: 20px !important;
      width: 20px !important;
      margin-bottom: 7px !important;
    }
  }
}

.image-box {
  // width: 90px;
  position: relative;
  .close {
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: #fff;
    border-radius: 50%;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 2px;
    mat-icon {
      zoom: 0.7;
    }
  }
  img {
    width: 90px;
    max-height: 200px;
    object-fit: cover;
    border-radius: 5px;
    // margin-bottom: 0.5rem;
    border: 1px solid var(--border-color);
  }
}

.full-screen-modal {
  max-width: none !important;
  // margin-top: 66px;
}

.mat-menu-panel {
  max-width: none !important;
}


.filter {
  max-width: 80vw !important;
  max-height: 80vh !important;
  min-width: 350px;
}

.textarea.form-control {
  min-height: 0 !important;
}

.a-color {
  color: white !important;
}

.mat-tab-label {
  width: 50%;
  font-family: "Ubuntu";
  font-weight: bold;
}

.show-ics {
  margin: 10px 0px 5px 0px;
  box-shadow: 0px 0px 3px 0px lightgrey;
  border-radius: 5px;
  cursor: pointer;
}

.uploadfilecontainer {
  height: 150px;
  width: 150px;
  margin: 5px 0px 10px 0px;
  border: 2px dashed #1c8adb;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.drag-image {
  height: 120px;
  width: 120px;
  border-radius: 50%;
  // margin-right: 15px;
  margin-left: 15px;
  object-fit: cover;
  // border : 1px solid lightgray;
  box-shadow: 0px 0px 3px 0px lightgrey;
}

.remove-drag-image {
  position: absolute !important;
  color: rgb(221, 10, 10);
  margin-left: 110px !important;
  margin-top: -162px !important;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: var(--main-color) !important;
}

.mentioned-you {
  font-weight: bold;
  color: orangered !important;
}

.mentioned-user {
  // color: blue !important;
  font-weight: bold;
}

.mentioned-user:hover {
  text-decoration: underline;
}

.total-count-text {
  font-size: 1rem;
}

.total-count-num {
  font-size: 1.1rem;
  font-weight: bold;
}

.excel-icon {
  width: 1.2rem;
  height: auto;
  margin-right: 10px;
}

.c-search-input-with-clear {
  border-right: none !important;
}

.c-clear-not-focus {
  display: flex !important;
  align-items: center !important;
  border: 1px solid #ced4da !important;
  border-left: none !important;
  background-color: white !important;
  &:hover {
    color: black !important;
  }
}

.c-clear {
  display: flex !important;
  align-items: center !important;
  border: 1px solid var(--main-color) !important;
  border-left: none !important;
  background-color: white !important;
  &:hover {
    color: black !important;
  }
}

.no-pad {
  width: 25% !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pad {
  width: 25% !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 16px !important;
}

.input-container {
  width: 75% !important;
}

::ng-deep .mat-sort-header-container {
  border: none !important;
}

//pagination

.custom-pagination {
  padding: 5px 8px;
  display: flex;
  align-items: center;
  font-size: 13px;
  height: 25px;
  &:hover {
    cursor: pointer;
    background-color: lightgray;
  }
}
.custom-pagination-disabled {
  display: flex;
  align-items: center;
  padding: 5px 8px;
  opacity: 0.6;
  font-size: 13px;
  height: 25px;
}
.page {
  min-width: 25px;
  height: 25px;
  margin: 0px 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  &:hover {
    cursor: pointer;
    background-color: lightgray;
  }
}
.active-page {
  min-width: 25px;
  height: 25px;
  margin: 0px 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  color: white;
  background-color: var(--main-color);
}

.offcanvas-backdrop.show {
  opacity: 0 !important;
}

.s0 {
  stroke: none !important;
}

.cc-label {
  color: var(--main-color);
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.calendar-menu {
  max-height: 300px !important;
}

.inactive-calender {
  color: red !important;
}

.input-calendar-menu {
  width: 350px !important;
  max-height: 300px !important;
}

.ngx-pagination {
  margin-bottom: 0 !important;
}

@media only screen and (max-width: 426px) {
  .form-select option {
    font-size: 9px;
  }
}

.advanced-filter-btn {
  background-color: #87929d !important;
  border-color: #87929d !important;
}

.ng-select.ng-select-disabled > .ng-select-container {
  background-color: #e9ecef !important;
}

// .cdk-overlay-pane {
//   transition: all 0.4s;
// }
.mat-dialog-height-transition {
  transition: height 0.5s;
}

.contact-tracing-details-modal {
  .mat-dialog-title {
    font-size: 1rem;
    .event-date {
      color: var(--main-color);
      font-size: 0.8rem;
      font-weight: 500;
      line-height: 1.5;
      opacity: 0.7;
      padding: 0;
      margin: 0;
    }
  }
}

.list-info:hover {
  cursor: pointer;
  span {
    color: var(--main-color) !important;
  }
  .pos {
    color: white !important;
    background-color: var(--main-color);
    border: 1px solid var(--main-color);
  }
}

.supervision-list-info:hover {
  cursor: pointer;
  span {
    color: red !important;
  }
  .pos {
    color: white !important;
    background-color: red !important;
    border: 1px solid red !important;
  }
}

.temp-status {
  color: white;
  border-radius: 2px;
  background-color: #00b400;
  padding: 1px 5px;
  display: flex;
  align-items: center;
}

.qrcode {
  img {
    width: 200px !important;
  }
}

.image-detail {
  mat-dialog-container {
    padding: 0 !important;
  }
}

.dashboard-custom-card {
  cursor: pointer;
  width: 46% !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
  border: 0.5px solid rgba(204, 202, 202, 0.39) !important;
  .name {
    font-size: 0.9rem;
  }
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
}

@media only screen and (min-width: 576px) {
  .dashboard-custom-card {
    width: max-content !important;
    min-width: 140px !important;
  }
}

.mat-icon {
  height: 1.5rem !important;
  width: 1.5rem !important;
  font-size: 1.5rem !important;
}

.mat-checkin-icon{
  height: 1.2rem !important;
  width: 1.2rem !important;
  font-size: 1.2rem !important;
}

.hidden-label {
  height: 1rem;
}

.td-data-no-min-width {
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 35px;
}

.remove-attachment {
  position: absolute !important;
  color: #dd0a0a;
  right: 0;
  top: 0;
}

.mat-option-text {
  font-size: 12px;
}

.mat-option {
  height: 37px !important;
}

.attendance-menu {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1) !important;
  margin-left: 100px !important;
  margin-right: -205px;
  margin-top: -33px;
  min-width: 150px !important;
  border-radius: 15px !important;
  font-family: "Ubuntu" !important;
}


.imageViewer .container{
  width: 100vw !important;
  max-width: 100vw !important;
}
