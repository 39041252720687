/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Ubuntu";
}

@import "~@angular/material/prebuilt-themes/indigo-pink.css";
/* loading//////////////////////////// */

.modal {
  text-align: center;
  margin-right: 30%;
  background-color: rgba(124, 122, 122, 0.21);
  background-color: rgb(51 51 51 / 21%);
}

.modal {
  text-align: center;
  margin-right: 30%;
  background-color: rgba(124, 122, 122, 0.21);
  background-color: rgb(51 51 51 / 21%);
}

.modal-content {
  background-color: var(--primary-background);
  top: 25%;
  border: none;
  border-radius: 0;
}

.loading {
  margin: auto;
  background-color: white;
  width: 100px;
  height: 100px;
  font-size: 17px;
  color: #737171;
  font-weight: 400;
  border-radius: 6px;
  box-shadow: rgb(0 0 0 / 10%) 0px 1px 20px 0px;
}



.loading-text {
  margin-top: 5px;
}
/* //////////////////no data */

#nodata {
  text-align: center;
  transform: translateY(140px);
  font-size: 17px;
  color: grey;
  font-family: "Ubuntu", sans-serif;
}

/* app bar ////////////////*/

#appbar {
  height: 50px;
  position: fixed;
  background-color: white;
  width: 100%;
  z-index: 1;
  top: 0;
  border-bottom: 0.01px solid;
  border-color: whitesmoke;
}

i.fas.fa-arrow-left {
  margin-left: 25px;
  color: transparent;
  margin-top: 18px;
  cursor: pointer;
}

.appbarTitle {
  color: black;
  font-family: sans-serif;
  font-weight: 500;
  margin-top: -26px;
  margin-left: 25px;
  text-align: left;
  font-size: 21px;
}

/* save////////////////////// */

.divider2 {
  background-color: #e2dcdc;
  height: 1px;
  margin-left: -24px;
  margin-right: -24px;
  margin-top: 20px;
  transform: translateY(5px);
}

.save {
  background-color: rgb(9 122 160 / 70%);
  color: white;
  border-radius: 3%;
  height: 30px;
  text-align: center;
}

/* route ///////////////////////*/

#route {
  display: flex;
  margin-top: 18px !important;
  margin-bottom: 25px;
  color: dimgrey;
  font-size: 17px;
  font-weight: 500;
  margin-left: 55px;
  cursor: pointer;
  width: 90%;
  overflow: hidden;
}

#route .route-paylist,
.route-payeelist,
.route-paydetail,
.route-payeedetail,
.route-payamount {
  margin-right: 5px;
  margin-left: 5px;
  color: rgb(64 118 136);
}

#route .route-paylist:hover,
.route-payeelist:hover,
.route-payeedetail:hover,
.route-paypayamount:hover {
  color: rgb(90 164 189);
  text-decoration: underline;
  cursor: pointer;
}

/* loadinggg */

#page-loading {
  top: 0;
  background-color: rgb(242 243 243 / 30%);
  z-index: 3;
  width: 100%;
  position: fixed;
  height: 100%;
}

.spinner {
  top: 40%;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  height: 40px;
  text-align: center;
  position: fixed;
  font-size: 10px;
}

.spinner > div {
  background-color: rgb(90 164 189);
  height: 60%;
  width: 6px;
  display: inline-block;
  margin: 1px;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}

::ng-deep .success {
  background-color: #3abd70;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.4px;
  transition: 1s;
  border-radius: 2px !important;
  box-shadow: none;
}

.whole-page-content {
  padding: 0px 40px 0px 40px;
}

@media screen and (max-width: 768px) {
  .whole-page-content {
    padding: 0px 10px 0px 10px;
  }
  #route {
    display: flex;
    margin-top: 18px !important;
    margin-bottom: 25px;
    color: dimgrey;
    font-size: 17px;
    font-weight: 500;
    margin-left: 25px;
    cursor: pointer;
    width: 90%;
    overflow: hidden;
  }
}
